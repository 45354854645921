<template lang="pug">
  div
    TheHeader
    v-layout.restore_confirm(align-center, justify-center, column, fill-height)
      section
        .restore_confirm__title {{ $t('messages.success.almostDone') }}
          SvgCheck.svg_check
        p(v-html="$t('messages.success.recoveryEmailSent', { email })")
        .main_link(:class="{ disabled: countdownActive }", @click="handleResendClick") {{ $t('actions.resendRecoveryEmail') }}
        .timer(v-if="countdownActive") {{ $t('messages.inform.timeLeftToRequestRestoreEmal') }}
          Countdown(:timer="fiveMinInMs", :timerStart="recoverySentTimestamp", @expired="countdownActive = false")
      v-dialog(v-model="showCaptchaDialog", width="300")
        v-card
          .close(@click="showCaptchaDialog = false")
            SvgPlus
          v-card-title {{ $t('formFields.captcha') }}
          v-card-text
            v-form(ref="captchaForm", v-model="captchaValid", @submit.prevent="")
              v-layout.captcha(v-if="captchaImage", align-center)
                img(v-if="!loadingNewCaptcha", :src="captchaImage")
                v-icon.reload(v-if="!loadingNewCaptcha", @click="getNewCaptchaImage") mdi-reload
              v-text-field(
                v-model="captcha",
                ref="captchaInput",
                :rules="[rules.required]",
                :label="$t('formFields.captcha')",
                :hint="$t('hints.captcha')",
                :persistent-hint="true",
                autocomplete="off",
                required)
              .errors(v-if="errorsCaptcha")
                p(v-for="error in errorsCaptcha", :key="error.title") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
              v-btn(:disabled="!captchaValid || loading", @click="submitForm", type="submit") {{ $t('actions.proceed') }}
</template>

<script>
import SvgCheck from '@/components/svg/SvgCheck';
import Countdown from '@/components/Countdown';
import SvgPlus from '@/components/svg/SvgPlus';
import TheHeader from '@/components/TheHeader';

export default {
  components: {
    SvgCheck,
    Countdown,
    SvgPlus,
    TheHeader
  },
  data() {
    return {
      countdownActive: false,
      fiveMinInMs: 5 * 60 * 1000,
      showCaptchaDialog: false,
      loadingNewCaptcha: false,
      captchaValid: false,
      errorsCaptcha: [],
      captcha: '',
      loading: false
    };
  },
  computed: {
    email() {
      return this.$store.state.user.recoverySent;
    },
    recoverySentTimestamp() {
      return this.$store.state.user.recoverySentTimestamp;
    },
    captchaId() {
      return this.$store.state.user.captchaId;
    },
    captchaImage() {
      return this.$store.state.user.captchaImage;
    },
    rules() {
      return {
        required: v => !!v.trim() || this.$t('validation.required')
      };
    }
  },
  methods: {
    handleResendClick() {
      if (this.countdownActive) return;

      if (!this.captchaId) {
        this.$store.dispatch('user/getCaptchaId');
      }

      this.getNewCaptchaImage();
    },
    submitForm() {
      if (!this.countdownActive) {
        const data = {
          data: {
            type: 'recovery-passwords',
            attributes: {
              email: this.email,
              captcha_id: this.captchaId,
              captcha_code: this.captcha
            }
          }
        };

        this.$store.dispatch('user/sendRecoveryEmail', data)
          .then(() => {
            this.showCaptchaDialog = false;
          })
          .catch(errors => {
            this.getNewCaptchaImage();
            this.errorsCaptcha = errors;
          });
      }
    },
    getNewCaptchaImage() {
      if (this.loadingNewCaptcha) return;

      this.loadingNewCaptcha = true;

      this.$store.dispatch('user/getCaptchaImage', this.captchaId)
        .then(() => {
          this.showCaptchaDialog = true;
          this.captcha = '';

          setTimeout(() => {
            this.$refs.captchaForm.resetValidation();
            this.$refs.captchaInput.focus();
          },
          0);
        })
        .catch(errors => console.log('Error in fetch captcha image', errors))
        .finally(() => { this.loadingNewCaptcha = false; });
    }
  },
  watch: {
    recoverySentTimestamp(val) {
      if (val) this.countdownActive = true;
    }
  },
  mounted() {
    if (!this.email) this.$router.push('/restore');

    if ((Date.now() - this.recoverySentTimestamp) < this.fiveMinInMs) {
      this.countdownActive = true;
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .restore_confirm {
    max-width: 550px;
    min-height: 100vh;
    padding: 0 10px;
    margin: auto;
    text-align: left;

    section {
      position: relative;
      padding-left: 80px;
      width: 100%;
    }

    &__title,
    p {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 52px;
      line-height: 1;
      color: $light-theme-primary-text;
      text-align: left;

      .svg_check {
        position: absolute;
        left: 0px;

        ::v-deep path { fill: $green !important; }
      }
      @media screen and (max-width: 400px) { font-size: 28px; }
    }

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
    }

    .main_link.disabled {
      color: $grey-text !important;
      cursor: auto;
    }

    .forgot_link { margin-top: 20px; }
    .timer { margin: 10px 0;}
  }

  .errors { color: $error-color; }

  ::v-deep .v-card__text { text-align: left !important; }

  .v-card {
    .captcha {
      margin-bottom: 15px;
      width: 200px;
      height: 60px;
      min-height: 60px;
    }

    .close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      outline: none;
      transform: rotate(45deg);
      margin: auto 0 auto auto;
      opacity: 0.54;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    ::v-deep .v-btn,
    ::v-deep .v-input {
      width: 260px;
    }
  }
</style>
